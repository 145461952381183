import React, { useEffect, useRef, useState } from "react";
import { ReactLenis } from "@studio-freight/react-lenis";
import { isTablet, isMobile } from "react-device-detect";

import parse from "html-react-parser";

import Outcome from "../utils/Outcome";
import { gtmClick, gtmView } from "../utils/gtmClick";

const QA = {
    "Q1": {
        question: "你正在高鐵列車上，<br />直覺你將前往的目的地是？",
        answers: ["回家", "出去玩", "出差"],
        tracking: ["home", "play", "business"],
        gtag: ["Hz3NCPXi29MZEOWkpbco", "iQT_CPji29MZEOWkpbco", "3rwUCPvi29MZEOWkpbco"]
    },
    "Q2": [
        {
            question: "你覺得這趟回家的原因是？",
            answers: ["心情不美麗", "返鄉投票", "媽媽炫耀煮了拿手菜", "農曆春節", "剛好搶到早鳥票"],
            heartLevel: [5, 1, 4, 2, 3]
        },
        {
            question: "是甚麼原因，讓你出發這趟旅行？",
            answers: ["心情不美麗，想出門散心", "別人揪的", "突然想吃道地的美食", "連假不知道要幹嘛", "搶到旅展優惠"],
            heartLevel: [3, 1, 5, 2, 4]
        },
        {
            question: "在出差的高鐵上，此刻你的心情如何？",
            answers: ["🤑", "😭", "😎", "😑", "😴"],
            heartLevel: [5, 1, 4, 2, 3]
        }
    ],
    "Q3": {
        question: "憑直覺選擇窗花？",
        answers: ["", ""]
    },
    "Q4": [
        {
            question: "週末要拜訪親戚需要準備送禮，你會選擇？",
            answers: ["上網爬文做功課，挑選氣派、有面子的伴手禮", "周末到當地再決定要買什麼就行了"]
        },
        {
            question: "週末早餐叫外送，你會選擇？",
            answers: ["加價就可以很快送達", "特價買但需要等待"]
        }
    ],
    "last": [
        {
            question: "憑直覺選擇，你的電腦桌布是？",
            answers: ["", "", ""],
            starLevel: [1, 3, 5]
        },
        {
            question: "憑直覺選擇，你的電腦桌布是？",
            answers: ["", "", ""],
            starLevel: [5, 3, 1]
        },
        {
            question: "假設跟朋友吃飯，<br/>你會？",
            answers: ["阿姆阿姆... 真好吃", "不先問價格，直接請客時價海鮮", "結帳時上演搶付錢經典大戲"],
            starLevel: [1, 5, 3]
        },
        {
            question: "假設今天是發薪日，<br/>你會？",
            answers: ["布丁撕開不舔蓋子", "不先問價格，直接點「時價」", "打折前一刻原價買"],
            starLevel: [5, 1, 3]
        }
    ]
};

const Quiz = ({ memberData, setPopup, alert, setAlert, setImagesProgress, setInfo, step, setStep, setResult }) => {
    const typeToNumber = ["海派", "隨興", "計劃通", "cp值"];
    const memberTypeToNumber = ["隨興", "計劃通", "海派", "cp值", "海派隱藏結果"];
    const timer = useRef(null);
    const canvas = useRef(null);
    const outcome = useRef(null);

    const [answer, setAnswer] = useState(new Array((memberData && memberData.totalKm !== 0 && memberData.totalTkt !== 0) ? 3 : 5).fill(null));
    const [nowQuestion, setNowQuestion] = useState(0);
    const [randomStation, setRandomStation] = useState([]);
    const [randomMonth, setRandomMonth] = useState([]);
    const [memberType, setMemberType] = useState(null);
    const [finalImage, setFinalImage] = useState("");
    const [link, setLink] = useState("");

    const reset = (step) => {
        setFinalImage("");
        setStep(step ? step : 0);
        setAnswer(new Array((memberData && memberData.totalKm !== 0 && memberData.totalTkt !== 0) ? 3 : 5).fill(null));
        setNowQuestion(0);
        setLink("");
        setResult("");

        if (memberData && memberData.mbrType.length > 0) {
            const [idx1] = [...Array(memberData.mbrType.length).keys()].sort(() => 0.5 - Math.random()).slice(0, 1);
            const lastNumber = memberTypeToNumber.findIndex(d => {
                if (memberData.mbrType[idx1].includes("計")) {
                    return d === "計劃通";
                } else {
                    return d === memberData.mbrType[idx1].toLowerCase()
                }
            });
            console.log(lastNumber);
            setMemberType(lastNumber);
        }
    };

    const kmToText = (km) => {
        if (km < 0) {
            return;
        }

        const ranges = [
            { min: 0, max: 89, message: `大約等於 ${km * 2} 座台北101` },
            { min: 90, max: 99, message: "你都可以忠孝東路走九遍了呢" },
            { min: 100, max: 100, message: "直接超過動力x車，忠孝東路走十遍!" },
            { min: 101, max: 469, message: `大約等於 ${km * 2} 座台北101` },
            { min: 470, max: 939, message: "足以繞行台灣半圈了呢" },
            { min: 940, max: 1345, message: "足以繞行台灣一圈了呢" },
            { min: 1346, max: 2016, message: "已經可以飛去韓國了呢" },
            { min: 2017, max: 2691, message: "已經可以飛去日本了呢" },
            { min: 2692, max: 4033, message: "已經可以來回韓國一趟了呢" },
            { min: 4034, max: 5741, message: "已經可以來回日本一趟了呢" },
            { min: 5742, max: 8738, message: "已經可以飛去澳洲了呢" },
            { min: 8739, max: 9445, message: "已經可以飛去歐洲了呢" },
            { min: 9446, max: 19999, message: "已經可以飛去冰島看極光了呢" },
            { min: 20000, max: 379999, message: `足以繞行地球${calculateCircles(km)}了呢` },
            { min: 380000, max: 759999, message: "足以從地球飛到月球了呢" }
        ];

        for (const range of ranges) {
            if (km >= range.min && km <= range.max) {
                return range.message;
            }
        }

        return "足以來回月球一趟了呢";
    };

    const calculateCircles = (value) => {
        const baseValue = 20000;
        const increment = 20000;
        const circleIncrement = 0.5;

        const circles = (Math.floor(((value - baseValue) / increment)) + 1) * circleIncrement;
        return convertToChinese(circles);
    };

    const convertToChinese = (num) => {
        const integerPart = Math.floor(num);
        const decimalPart = num - integerPart;

        let result = "";

        const integerMapping = ["零", "一", "兩", "三", "四", "五", "六", "七", "八", "九"];

        if (integerPart > 0) {
            result += integerMapping[integerPart];
        } else if (integerPart === 0 && decimalPart === 0) {
            result += "零";
        }

        if (decimalPart === 0.5) {
            if (integerPart > 0) {
                result += "圈半";
            } else {
                result += "半圈";
            }
        } else if (decimalPart === 0) {
            result += "圈";
        } else if (integerPart > 0 && decimalPart === 0) {
            result += "圈";
        }

        return result;
    };

    const getRandom = (array) => {
        if (array.length < 2) return array;

        const [idx1, idx2] = [...Array(array.length).keys()]
            .sort(() => 0.5 - Math.random())
            .slice(0, 2);

        const element1 = array[idx1];
        const element2 = array[idx2];

        return idx1 < idx2 ? [element1, element2] : [element2, element1];
    };

    const findMaxCountObjects = (array) => {
        if (array.length === 0) return [];

        const maxCount = array.reduce((max, item) => {
            return item.count > max ? item.count : max;
        }, array[0].count);

        return array.filter(item => item.count === maxCount);
    };

    const resultRecord = (a, m) => {
        const result = [
            [
                "歡喜就好無所WAY",
                "就愛宅家這一WAY",
                "阿哩呷飽WAY",
                "媽媽一直WAY"
            ],
            [
                "說走就走的 myWAY",
                "完美攻略最到WAY",
                "玩好玩滿才夠WAY",
                "懂玩懂省有品WAY"
            ],
            [
                "有緣作伙的人情WAY",
                "全部給我各就各WAY～",
                "WAY～太阿莎力了吧",
                "自帶智慧的大省WAY"
            ]
        ];

        if (m === 4) {
            return "口袋深度耐人尋WAY"
        } else {
            return result[a][m];
        }

    };

    useEffect(() => {
        outcome.current = new Outcome({ canvas: canvas.current, setFinalImage: setFinalImage, setImagesProgress: setImagesProgress, setLink: setLink });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        reset();

        if (memberData) {
            setRandomStation(getRandom(memberData.topStation));
            const maxCountObjects = findMaxCountObjects(memberData.topMonthCount);
            setRandomMonth(getRandom(maxCountObjects).sort((a, b) => a.month - b.month));

            if (outcome.current) {
                outcome.current.resize(memberData);
            }
        }
        // eslint-disable-next-line
    }, [memberData]);

    useEffect(() => {
        if ((answer.filter(d => d !== null).length === answer.length) && memberType !== null) {
            outcome.current.draw(answer, memberType);
            setResult(resultRecord(answer[0], memberType));
            gtmView("part-3-test-end");
        }
        // eslint-disable-next-line
    }, [answer, memberType]);


    const isIOS = () => {
        return /iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream;
    };

    const share = async(finalImage) => {
        if (window.navigator.share) {
            const blob = await (await fetch(finalImage)).blob();
            const file = new File([blob], "最真實的台灣WAY.jpg", { type: blob.type });

            if (isIOS()) {
                window.navigator.share({
                    title: "每個台灣人都有自己的台灣WAY，測出你的台灣WAY，有機會獨得 12 張高鐵票！ https://thsrcevent.com.tw/?utm_source=facebook&utm_medium=social&utm_campaign=taiwan-way",
                    files: [file]
                })
                .then(() => console.log("分享成功"))
                .catch(error => console.log("分享失敗", error));
            } else {
                window.navigator.share({
                    title: "每個台灣人都有自己的台灣WAY，測出你的台灣WAY，有機會獨得 12 張高鐵票！ https://thsrcevent.com.tw/?utm_source=facebook&utm_medium=social&utm_campaign=taiwan-way",
                    text: "每個台灣人都有自己的台灣WAY，測出你的台灣WAY，有機會獨得 12 張高鐵票！ https://thsrcevent.com.tw/?utm_source=facebook&utm_medium=social&utm_campaign=taiwan-way/"
                })
                .then(() => console.log("分享成功"))
                .catch(error => console.log("分享失敗", error));
            }
        }
    };

    return (
        <>
            <section id="mytaiwanway" className="section-quiz">
                <div className="quiz-wrapper" style={{ backgroundImage: "url(/images/pic-background.jpg?20240919)" }}>
                    <div className="train" style={{ backgroundImage: "url(/images/pic-train.png?20240919)" }}></div>
                    <div className="ground" style={{ backgroundImage: "url(/images/pic-ground.png?20240919)" }}></div>
                    <div className={`balloon${step < 3 ? " display" : ""}`} style={{ backgroundImage: "url(/images/pic-balloon.png?20240919)" }}></div>
                    <div className={`main-scene${step === 0 ? " display" : ""}`}>
                        <div className="title-group">
                            <h2 className="quiz-title">
                                <img src="../images/title-quiz.png" alt="你是哪種台灣WAY" />
                            </h2>
                        </div>

                        <p className="quiz-paragraph paragraph-pc">
                            測出屬於你的台灣WAY，有機會<span className="text-orange">獨得12張高鐵票</span>或<span className="text-orange">高鐵假期雙人遊</span>
                            <br/>
                            TGo會員再享專屬數據回顧，快來看看你是什麼WAY？
                        </p>
                        <p className="quiz-paragraph paragraph-mo">
                            測出屬於你的台灣WAY<br/>
                            有機會<span className="text-orange">獨得12張高鐵票</span>或<span className="text-orange">高鐵假期雙人遊</span>
                            <br/>
                            <br/>
                            TGo會員再享專屬數據回顧<br/>
                            快來看看你是什麼WAY？
                        </p>

                        <div className="btn-group">
                            {!memberData &&
                                <>
                                    <a className="btn btn-orange" href="https://tgo.thsrc.com.tw/login.html?returnurl=thsrcevent">
                                        <span className="text">TGo會員<br/>登入測驗</span>
                                    </a>
                                    <button className="btn btn-blue" onClick={() => {
                                        setStep(2);
                                        gtmClick("nmt-0-start");
                                    }}>
                                        <span className="text">立即測驗</span>
                                    </button>
                                </>
                            }
                            {memberData &&
                                <button className="btn btn-orange" onClick={() => { setStep(answer.length === 3 ? 1 : 2); }}>
                                    <span className="text">測驗開始</span>
                                </button>
                            }
                        </div>
                        <span className="btn-info" onClick={() => { setInfo(true); }}>活動辦法</span>
                    </div>

                    <div className={`intro-scene${step === 1 ? " display" : ""}`}>
                        {answer.length === 3 &&
                            <>
                                <p className="data-paragraph">
                                    這一年，你最常在 {randomMonth.map((data, index) => (
                                        <React.Fragment key={`month-${data.month}`}>
                                            {index > 0 ? "、" : ""}<span className="text-data">{data.month}</span> <span className="text-orange">月</span>
                                        </React.Fragment>
                                    ))} 搭乘高鐵<br/>
                                    訂了 <span className="text-data">{memberData.totalTkt}</span> <span className="text-orange">張</span> 高鐵車票<br/>
                                    遇見了 <span className="text-data">{memberData.totalTkt}</span> <span className="text-orange">種</span> <span className="br"></span>
                                    山珍海WAY、家鄉WAY、人情WAY
                                </p>
                                <p className="data-paragraph">
                                    你最常出入的高鐵站是 <span className="br"></span>
                                    {randomStation.map((station, index) =>
                                        <React.Fragment key={`station_1-${station}`}>
                                            {index > 0 ? "、" : ""}<span className="text-data">{station}</span> <span className="text-orange text-l">站</span>
                                        </React.Fragment>
                                    )}<br />
                                    看來 {randomStation.map((station, index) =>
                                        <React.Fragment key={`station_2=${station}`}>
                                            {index > 0 ? "、" : ""}<span className="text-data">{station}</span>
                                        </React.Fragment>
                                    )} <span className="br"></span>
                                    對你來說意義深刻、別有風WAY
                                </p>
                                <p className="data-paragraph">
                                    一年內，你搭乘高鐵的總里程 <span className="br"></span>
                                    <span className="text-data">{memberData.totalKm}</span> <span className="text-orange text-l">公里</span><br/>
                                    {kmToText(memberData.totalKm)}
                                </p>
                                <button className="btn btn-orange btn-skip" onClick={() => { setStep(2); }}>
                                    <span className="text">下一步</span>
                                </button>
                            </>
                        }
                    </div>

                    <div className={`intro-scene${step === 2 ? " display" : ""}`}>
                        <p className="intro-paragraph">眼前突然被一片白霧籠罩</p>
                        <p
                            className="intro-paragraph"
                            onTransitionEnd={() => {
                                if (step === 2) {
                                    if (timer.current) {
                                        clearTimeout(timer.current);
                                    }
                                    timer.current = setTimeout(() => {
                                        setStep(3);
                                    }, 2000);
                                }
                            }}
                        >等霧氣消散，你赫然發現...</p>
                    </div>

                    <div className={`quiz-scene${step === 3 ? " display" : ""}`}>
                        <div className="quiz-box">
                            <div className="bg"></div>

                            <div className={`question question_1${nowQuestion === 0 ? " display" : ""}`}>
                                <h3 className="quiz-title">Q1<br/>{parse(QA["Q1"].question)}</h3>

                                <div className="quiz-answers">
                                    {QA["Q1"].answers.map((a, i) => (
                                        <button key={a} className="btn-answer" onClick={() => {
                                            const newAnswer = [...answer];
                                            newAnswer[nowQuestion] = i;
                                            setAnswer(newAnswer);
                                            setNowQuestion(nowQuestion + 1);
                                            gtmClick(`${answer.length === 5 ? "n" : ""}mt-q1-${i + 1}`);

                                            if (window.fbq) {
                                                window.fbq("trackSingleCustom", "221730302773316", "Initiatecheckout", { product_id: `202409${QA["Q1"].tracking[i]}` });
                                            }

                                            if (window.gtag) {
                                                window.gtag("event", "conversion", {
                                                    "send_to": `AW-10853372517/${QA["Q1"].gtag[i]}`,
                                                    "value": 1.0,
                                                    "currency": "TWD"
                                                });
                                            }
                                        }}>{a}</button>
                                    ))}
                                </div>
                            </div>

                            <div className={`question question_2${nowQuestion === 1 ? ` display` : ""}`}>
                                {answer[0] !== null &&
                                    <>
                                        <h3 className="quiz-title">Q2<br/>{parse(QA["Q2"][answer[0]].question)}</h3>
                                        <div className="quiz-answers">
                                            {QA["Q2"][answer[0]].answers.map((a, i) => (
                                                <button key={a} className="btn-answer" onClick={() => {
                                                    const newAnswer = [...answer];
                                                    newAnswer[nowQuestion] = QA["Q2"][answer[0]].heartLevel[i];
                                                    setAnswer(newAnswer);
                                                    setNowQuestion(nowQuestion + 1);
                                                    gtmClick(`${answer.length === 5 ? "n" : ""}mt-q2-${i + 1}`);
                                                }}>{a}</button>
                                            ))}
                                        </div>
                                    </>
                                }
                            </div>

                            <div className={`question question_3${nowQuestion === 2 ? ` display` : ""}`}>
                                {answer[1] !== null &&
                                    <>
                                        <h3 className="quiz-title">
                                            Q3<br />
                                            {answer.length === 3 ?
                                                parse(QA["last"][memberType === 4 ? 2 : memberType].question) :
                                                parse(QA["Q3"].question)
                                            }
                                        </h3>
                                        <div className="quiz-answers">
                                            {answer.length === 3 ?
                                                QA["last"][memberType === 4 ? 2 : memberType].answers.map((a, i) =>
                                                    <button key={`桌布${i+1}`} className={(memberType === 4 ? 2 : memberType) < 2 ? "btn-desktop" : "btn-answer"} onClick={() => {
                                                        const newAnswer = [...answer];
                                                        newAnswer[nowQuestion] = QA["last"][memberType === 4 ? 2 : memberType].starLevel[i];
                                                        setAnswer(newAnswer);
                                                        setStep(4);
                                                        gtmClick(`mt-q3-${i + 1}`);
                                                    }}>
                                                        {(memberType === 4 ? 2 : memberType) < 2 ?
                                                            <div className="image-wrapper">
                                                                <img className="pic-pc" src={`../images/pic-desktop_${i+1}.png?20240919`} alt={`桌布${i+1}`} />
                                                                <img className="pic-mo" src={`../images/pic-desktop-mo_${i+1}.png?20240919`} alt={`桌布${i+1}`} />
                                                            </div> :
                                                            <>{a}</>
                                                        }
                                                    </button>
                                                ) :
                                                QA["Q3"].answers.map((a, i) => (
                                                    <button key={`窗花${i+1}`} className="btn-img" onClick={() => {
                                                        const newAnswer = [...answer];
                                                        newAnswer[nowQuestion] = 1 - i;
                                                        setAnswer(newAnswer);
                                                        setNowQuestion(nowQuestion + 1);
                                                        gtmClick(`nmt-q3-${i + 1}`);
                                                    }}>
                                                        <img src={`../images/pic-window_${i+1}.png?20240919`} alt={`窗花${i+1}`} />
                                                    </button>
                                                ))
                                            }
                                        </div>
                                    </>
                                }
                            </div>

                            {answer.length === 5 &&
                                <>
                                    <div className={`question question_4${nowQuestion === 3 ? ` display` : ""}`}>
                                        {answer[2] !== null &&
                                            <>
                                                <h3 className="quiz-title">Q4<br/>{parse(QA["Q4"][answer[2]].question)}</h3>
                                                <div className="quiz-answers">
                                                    {QA["Q4"][answer[2]].answers.map((a, i) => (
                                                        <button key={a} className="btn-answer" onClick={() => {
                                                            const newAnswer = [...answer];
                                                            newAnswer[nowQuestion] = answer[2] * 2 + i;
                                                            setAnswer(newAnswer);
                                                            setNowQuestion(nowQuestion + 1);
                                                            setMemberType(memberTypeToNumber.findIndex(d => d === typeToNumber[answer[2] * 2 + i]));
                                                            gtmClick(`nmt-q4-${i + 1}`);
                                                        }}>{a}</button>
                                                    ))}
                                                </div>
                                            </>
                                        }
                                    </div>

                                    <div className={`question question_5${nowQuestion === 4 ? ` display` : ""}`}>
                                        {answer[3] !== null &&
                                            <>
                                                <h3 className="quiz-title">Q5<br/>{parse(QA["last"][memberType === 4 ? 2 : memberType].question)}</h3>
                                                <div className="quiz-answers">
                                                    {QA["last"][memberType === 4 ? 2 : memberType].answers.map((a, i) => (
                                                        <button key={`桌布${i+1}`} className={(memberType === 4 ? 2 : memberType) < 2 ? "btn-desktop" : "btn-answer"} onClick={() => {
                                                            const newAnswer = [...answer];
                                                            newAnswer[nowQuestion] = QA["last"][memberType === 4 ? 2 : memberType].starLevel[i];
                                                            setAnswer(newAnswer);
                                                            setStep(4);
                                                            gtmClick(`nmt-q5-${i + 1}`);
                                                        }}>
                                                            {(memberType === 4 ? 2 : memberType) < 2 ?
                                                                <div className="image-wrapper">
                                                                    <img className="pic-pc" src={`../images/pic-desktop_${i+1}.png?20240919`} alt={`桌布${i+1}`} />
                                                                    <img className="pic-mo" src={`../images/pic-desktop-mo_${i+1}.png?20240919`} alt={`桌布${i+1}`} />
                                                                </div> :
                                                                <>{a}</>
                                                            }
                                                        </button>
                                                    ))}
                                                </div>
                                            </>
                                        }
                                    </div>
                                </>
                            }

                            <div className="quiz-nav">
                                {answer.map((a, index) => (
                                    <div
                                        key={`question_${index}`}
                                        className={`quiz-unit${nowQuestion === index ? " now-selected" : ""}${index < nowQuestion ? "" : " disabled"}`}
                                        onClick={() => {
                                            if (index < nowQuestion) {
                                                const newAnswer = [...answer];
                                                newAnswer.forEach((a, i) => {
                                                    if (i >= index) {
                                                        newAnswer[i] = null;
                                                    }
                                                });
                                                setAnswer(newAnswer);
                                                setNowQuestion(index);
                                            }
                                        }}
                                    ></div>
                                ))}
                            </div>
                        </div>

                        <div className="deco-group">
                            <div className={`deco${nowQuestion === 0 ? " display" : ""}`}>
                                <div className="deco-leaf deco-leaf-left" style={{ backgroundImage: "url(/images/pic-leaf-left.png?20240919)" }}></div>
                                <div className="deco-leaf deco-leaf-right" style={{ backgroundImage: "url(/images/pic-leaf-right.png?20240919)" }}></div>
                            </div>

                            <div className={`deco${nowQuestion === 1 ? " display" : ""}`}>
                                <div className="deco-backpack" style={{ backgroundImage: "url(/images/pic-backpack.png?20240919)" }}></div>
                                <div className="deco-luggage" style={{ backgroundImage: "url(/images/pic-luggage.png?20240919)" }}></div>
                            </div>
                            {answer.length === 3 &&
                                <>
                                    <div className={`deco${(answer[1] !== null && memberType === 0) ? " display" : ""}`}>
                                        <div className="deco-computer" style={{ backgroundImage: "url(/images/pic-mac.png?20240919)" }}></div>
                                        <div className="deco-stationery" style={{ backgroundImage: "url(/images/pic-stationery.png?20240919)" }}></div>
                                    </div>

                                    <div className={`deco${(answer[1] !== null && memberType === 1) ? " display" : ""}`}>
                                        <div className="deco-fish" style={{ backgroundImage: "url(/images/pic-fish.png?20240919)" }}></div>
                                        <div className="deco-shrimp" style={{ backgroundImage: "url(/images/pic-shrimp.png?20240919)" }}></div>
                                        <div className="deco-cash deco-cash-left" style={{ backgroundImage: "url(/images/pic-cash-left.png?20240919)" }}></div>
                                        <div className="deco-cash deco-cash-right" style={{ backgroundImage: "url(/images/pic-cash-right.png?20240919)" }}></div>
                                    </div>

                                    <div className={`deco${(answer[1] !== null && memberType === 2) ? " display" : ""}`}>
                                        <div className="deco-money deco-money-left" style={{ backgroundImage: "url(/images/pic-money-left.png?20240919)" }}></div>
                                        <div className="deco-money deco-money-right" style={{ backgroundImage: "url(/images/pic-money-right.png?20240919)" }}></div>
                                    </div>
                                </>
                            }
                            {answer.length === 5 &&
                                <>
                                    <div className={`deco${(answer[3] !== null && memberType === 0) ? " display" : ""}`}>
                                        <div className="deco-computer" style={{ backgroundImage: "url(/images/pic-mac.png?20240919)" }}></div>
                                        <div className="deco-stationery" style={{ backgroundImage: "url(/images/pic-stationery.png?20240919)" }}></div>
                                    </div>

                                    <div className={`deco${(answer[3] !== null && memberType === 1) ? " display" : ""}`}>
                                        <div className="deco-fish" style={{ backgroundImage: "url(/images/pic-fish.png?20240919)" }}></div>
                                        <div className="deco-shrimp" style={{ backgroundImage: "url(/images/pic-shrimp.png?20240919)" }}></div>
                                        <div className="deco-cash deco-cash-left" style={{ backgroundImage: "url(/images/pic-cash-left.png?20240919)" }}></div>
                                        <div className="deco-cash deco-cash-right" style={{ backgroundImage: "url(/images/pic-cash-right.png?20240919)" }}></div>
                                    </div>

                                    <div className={`deco${(answer[3] !== null && memberType === 2) ? " display" : ""}`}>
                                        <div className="deco-money deco-money-left" style={{ backgroundImage: "url(/images/pic-money-left.png?20240919)" }}></div>
                                        <div className="deco-money deco-money-right" style={{ backgroundImage: "url(/images/pic-money-right.png?20240919)" }}></div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                    <ReactLenis className={`outcome-scene${step === 4 ? " display" : ""}`}>
                        <div className={`outcome-image${memberData ? " is-member" : ""}`}>
                            <canvas ref={canvas} />
                            {finalImage !== "" &&
                                <>
                                    <img src={finalImage} alt="outcome" />
                                    {link && <a className="btn-link" href={link} target="_blank" rel="noreferrer">link</a>}
                                </>
                            }
                        </div>
                        <p className="hint-text">
                            {(isTablet || isMobile) && "▲  長按上方結果圖儲存測驗結果  ▲"}
                            {!(isTablet || isMobile) && "▲  右鍵另存測驗結果  ▲"}
                        </p>
                        <div className="btn-group large">
                            <button className="btn btn-blue" onClick={() => {
                                setPopup(true);
                                gtmClick(`${answer.length === 5 ? "n" : ""}mt-result-lottery-join`);
                            }}>
                                <span className="text">抽免費高鐵票</span>
                            </button>
                            {(isTablet || isMobile) &&
                                <button className="btn btn-orange" onClick={() => {
                                    share(finalImage);
                                    gtmClick(`${answer.length === 5 ? "n" : ""}mt-result-share`);
                                }}>
                                    <span className="text">立即分享</span>
                                </button>
                            }
                            {!(isTablet || isMobile) &&
                                <a
                                    className="btn btn-orange"
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.facebook.com/dialog/share?display=popup&href=https%3A%2F%2Fthsrcevent.com.tw%2F%3Futm_source%3Dfacebook%26utm_medium%3Dsocial%26utm_campaign%3Dtaiwan-way&redirect_uri=https%3A%2F%2Fthsrcevent.com.tw%2F"
                                    onClick={() => {
                                        gtmClick(`${answer.length === 5 ? "n" : ""}mt-result-share`);
                                    }}
                                >
                                    <span className="text">立即分享</span>
                                </a>
                            }
                            <button className="btn btn-black" onClick={() => {
                                reset(3);
                                gtmClick(`${answer.length === 5 ? "n" : ""}mt-result-share`);
                            }}>
                                <span className="text">再玩一次</span>
                            </button>
                        </div>
                    </ReactLenis>
                </div>
            </section>


            <div className={`alert${alert.display ? " display" : ""}`}>
                <div className="bg" onClick={() => { setAlert({ status: null, display: false }); }}></div>
                <div className="alert-box">
                    <h3 className="alert-title">
                        {alert.status === 200 && "成功參與抽獎"}
                        {alert.status === 400 && "今日已參加過嘍"}
                    </h3>
                    <p className="alert-paragraph">
                        {alert.status === 200 && <>中獎名單將於<span className="br"></span> 2024/10/29 公布於活動網頁</>}
                        {alert.status === 400 && "明天再來吧！"}
                    </p>
                    <div className="btn-group">
                        {(isTablet || isMobile) &&
                            <button className="btn btn-orange" onClick={() => {
                                share(finalImage);
                                gtmClick(`${answer.length === 5 ? "n" : ""}mt-result-share`);
                            }}>
                                <span className="text">立即分享</span>
                            </button>
                        }
                        {!(isTablet || isMobile) &&
                            <a
                                className="btn btn-orange"
                                target="_blank"
                                rel="noreferrer"
                                href="https://www.facebook.com/dialog/share?display=popup&href=https%3A%2F%2Fthsrcevent.com.tw%2F%3Futm_source%3Dfacebook%26utm_medium%3Dsocial%26utm_campaign%3Dtaiwan-way&redirect_uri=https%3A%2F%2Fthsrcevent.com.tw%2F"
                                onClick={() => {
                                    gtmClick(`${answer.length === 5 ? "n" : ""}mt-result-share`);
                                }}
                            >
                                <span className="text">立即分享</span>
                            </a>
                        }

                        <button className="btn btn-orange" onClick={() => {
                            reset(3);
                            setAlert({ status: null, display: false });
                        }}>
                            <span className="text">再玩一次</span>
                        </button>
                    </div>
                    <button className="btn-close" onClick={() => { setAlert({ status: null, display: false }); }}></button>
                </div>
            </div>
        </>
    );
};

export default Quiz;